<!--
 * @Author: 李洋
 * @Date: 2021-07-02 15:20:00
 * @LastEditors: 李洋
 * @LastEditTime: 2021-07-02 17:50:35
 * @Description: 产品损益
-->
<template>
  <div class="custom-container">
    <div class="table-container">
      <div class="title">北大荒完达山乳业2021年6月产品别损益分析报表</div>
      <vxe-table border align="center" :data="tableData">
        <vxe-table-column field="a" title="品类" align="left"></vxe-table-column>
        <vxe-table-column field="a1" title="品系" align="left"></vxe-table-column>
        <vxe-table-colgroup title="收入">
          <vxe-table-column field="b" title="计划"></vxe-table-column>
          <vxe-table-column field="c" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="成本">
          <vxe-table-column field="d" title="计划"></vxe-table-column>
          <vxe-table-column field="e" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="营销费用">
          <vxe-table-column field="f" title="计划"></vxe-table-column>
          <vxe-table-column field="g" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="利润">
          <vxe-table-column field="h" title="计划"></vxe-table-column>
          <vxe-table-column field="i" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="利润率">
          <vxe-table-column field="j" title="计划"></vxe-table-column>
          <vxe-table-column field="k" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
      </vxe-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {

          a: '婴幼儿奶粉', a1: '格致', b: '800000.00', c: '880000.00', d: '320000.00', e: '281600.00', f: '200000.00', g: '252837.00', h: '280000.00', i: '345563.00', j: '35.00', k: '39.27',
        },
        {
          a: '婴幼儿奶粉', a1: '菁采', b: '700000.00', c: '770000.00', d: '280000.00', e: '246400.00', f: '100000.00', g: '105283.00', h: '240000.00', i: '320366.00', j: '26.67', k: '32.36',
        },
        {
          a: '婴幼儿奶粉', a1: '菁润', b: '900000.00', c: '990000.00', d: '360000.00', e: '316800.00', f: '300000.00', g: '352834.00', h: '240000.00', i: '320366.00', j: '41.82', k: '51.03',
        },
        {
          a: '婴幼儿奶粉', a1: '亲蓓', b: '1100000.00', c: '1210000.00', d: '440000.00', e: '387200.00', f: '200000.00', g: '205283.00', h: '460000.00', i: '617517.00', j: '480000.00', k: '480000.00',
        },
        {
          a: '婴幼儿奶粉', a1: '忧巧', b: '500000.00', c: '550000.00', d: '200000.00', e: '176000.00', f: '100000.00', g: '152837.00', h: '200000.00', i: '221163.00', j: '40.00', k: '40.21',
        },
        {
          a: '婴幼儿奶粉', a1: '育见', b: '400000.00', c: '440000.00', d: '160000.00', e: '140800.00', f: '100000.00', g: '152837.00', h: '140000.00', i: '146363.00', j: '35.00', k: '33.26',
        },
        {
          a: '液态奶', a1: '黑沃', b: '600000.00', c: '660000.00', d: '240000.00', e: '211200.00', f: '100000.00', g: '152837.00', h: '260000.00', i: '295963.00', j: '43.33', k: '44.84',
        },
        {
          a: '液态奶', a1: '臻醇', b: '600000.00', c: '660000.00', d: '240000.00', e: '211200.00', f: '100000.00', g: '152384.00', h: '260000.00', i: '296416.00', j: '43.33', k: '44.91',
        },
        {
          a: '液态奶', a1: '益养', b: '600000.00', c: '600000.00', d: '240000.00', e: '211200.00', f: '100000.00', g: '152832.00', h: '260000.00', i: '295968.00', j: '43.33', k: '44.84',
        },
        {
          a: '液态奶', a1: '艾菲娅', b: '600000.00', c: '600000.00', d: '240000.00', e: '211200.00', f: '100000.00', g: '152837.00', h: '260000.00', i: '295963.00', j: '43.33', k: '44.84',
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}
.custom-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.title {
  font-size: 20px;
  text-align: center;
  color: #333333;
  font-weight: bold;
  padding: 10px 0;
}
.echarts-container {
  display: flex;
  align-items: stretch;
}
.map-container,
.bar-container {
  flex: 1;
  height: 400px;
}
</style>
