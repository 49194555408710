var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-container" }, [
    _c(
      "div",
      { staticClass: "table-container" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("北大荒完达山乳业2021年6月产品别损益分析报表"),
        ]),
        _c(
          "vxe-table",
          { attrs: { border: "", align: "center", data: _vm.tableData } },
          [
            _c("vxe-table-column", {
              attrs: { field: "a", title: "品类", align: "left" },
            }),
            _c("vxe-table-column", {
              attrs: { field: "a1", title: "品系", align: "left" },
            }),
            _c(
              "vxe-table-colgroup",
              { attrs: { title: "收入" } },
              [
                _c("vxe-table-column", {
                  attrs: { field: "b", title: "计划" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "c", title: "实际" },
                }),
              ],
              1
            ),
            _c(
              "vxe-table-colgroup",
              { attrs: { title: "成本" } },
              [
                _c("vxe-table-column", {
                  attrs: { field: "d", title: "计划" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "e", title: "实际" },
                }),
              ],
              1
            ),
            _c(
              "vxe-table-colgroup",
              { attrs: { title: "营销费用" } },
              [
                _c("vxe-table-column", {
                  attrs: { field: "f", title: "计划" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "g", title: "实际" },
                }),
              ],
              1
            ),
            _c(
              "vxe-table-colgroup",
              { attrs: { title: "利润" } },
              [
                _c("vxe-table-column", {
                  attrs: { field: "h", title: "计划" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "i", title: "实际" },
                }),
              ],
              1
            ),
            _c(
              "vxe-table-colgroup",
              { attrs: { title: "利润率" } },
              [
                _c("vxe-table-column", {
                  attrs: { field: "j", title: "计划" },
                }),
                _c("vxe-table-column", {
                  attrs: { field: "k", title: "实际" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }